<template>
    <div class="container">
        <span v-if="item" class="text-h3">{{ item.title }}</span>
        <v-row align="center" justify="center" style="height: 11vh">
            <v-col cols="12">
                <span v-if="item" @click="openDialog(actualHour)" style="cursor: pointer" class="text-center text-h6">{{
                    getTitleHour(actualHour) }}</span>
            </v-col>
        </v-row>

        <v-row align="center" justify="center">
            <v-col cols="12">
                <canvas ref="clockCanvas" @click="handleCanvasClick" @mousemove="handleMouseMove"></canvas>
            </v-col>
        </v-row>


        <v-dialog v-model="dialog" width="80%">
            <v-card class="pa-4">
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text v-html="dialogText"></v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { APIService } from "@/http/APIService.js";
const apiService = new APIService();

export default {
    name: "clocks",
    data() {
        return {
            item: null,
            dialog: false,
            dialogText: "",
            dialogTitle: "",
            hours: Array.from({ length: 12 }, (_, i) => i + 1),
            radius: 90,
            centerX: 100,
            centerY: 100,
            size: 200,
            elapsedTime: new Date(0),
            ctx: null,
            actualHour: 12
        };
    },
    mounted() {
        this.getItem();
        this.drawClock();
        this.resizeHandler(null);
    },

    created() {
        window.addEventListener("resize", this.resizeHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },


    methods: {
        resizeHandler(e) {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.size = 275;
            } else {
                this.size = 500;
            }

            this.radius = this.size / 2.22;
            this.centerX = this.size / 2;
            this.centerY = this.size / 2;
        },

        getItem() {
            apiService.getClocksPage().then(response => {
                this.item = response;
            });
        },

        getTitleHour(hour) {
            if (!this.item[`clock_${hour}_title`])
                return "";
            return this.item[`clock_${hour}_title`]; // Access properties directly
        },

        openDialog(hour) {
            this.dialog = true;
            this.dialogText = this.item[`clock_${hour}_text`]; // Access properties directly
            this.dialogTitle = this.item[`clock_${hour}_title`]; // Access properties directly
        },

        drawClock() {
            const canvas = this.$refs.clockCanvas;
            const ctx = canvas.getContext("2d");

            this.ctx = ctx;

            const updateTime = () => {
                const scale = window.devicePixelRatio;
                // console.log(scale);

                canvas.style.width = `${this.size}px`;
                canvas.style.height = `${this.size}px`;


                canvas.width = Math.floor(this.size * scale);
                canvas.height = Math.floor(this.size * scale);
                // ctx.scale(scale, scale);


                let radius = canvas.height / 2;
                ctx.translate(radius, radius);
                radius = radius * 0.9;

                this.drawFace(ctx, radius);
                this.drawNumbers(ctx, radius);
                this.drawTime(ctx, radius);
            };

            setInterval(updateTime, 10);
        },

        drawFace(ctx, radius) {
            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, 2 * Math.PI);
            ctx.fillStyle = 'white';
            ctx.fill();
            ctx.lineWidth = radius * 0.05;
            ctx.stroke();


            ctx.beginPath();
            ctx.arc(0, 0, radius * 0.06, 0, 2 * Math.PI);
            ctx.fillStyle = '#333';
            ctx.fill();
        },

        drawNumbers(ctx, radius) {
            ctx.font = radius * 0.15 + "px arial";
            ctx.textBaseline = "middle";
            ctx.textAlign = "center";
            for (let num = 1; num < 13; num++) {
                let ang = num * Math.PI / 6;
                ctx.rotate(ang);
                ctx.translate(0, -radius * 0.85);
                ctx.rotate(-ang);
                if ((num == this.elapsedTime.getHours()) || (12 + num == this.elapsedTime.getHours()) || (this.elapsedTime.getHours() == 0 && num == 12)) {
                    this.actualHour = num;
                    ctx.font = "bold " + radius * 0.25 + "px arial";
                }
                else if (this.elapsedTime.getHours() == 0 && num == 12) {
                    this.actualHour = num;
                    ctx.font = "bold " + radius * 0.25 + "px arial";
                }
                else {
                    ctx.font = radius * 0.15 + "px arial";
                }

                ctx.fillText(num.toString(), 0, 0);
                ctx.rotate(ang);
                ctx.translate(0, radius * 0.85);
                ctx.rotate(-ang);
            }
        },

        drawTime(ctx, radius) {
            if (!this.dialog)
                this.elapsedTime = new Date(this.elapsedTime.getTime() + 10000);

            let hour = this.elapsedTime.getHours();
            let minute = this.elapsedTime.getMinutes();
            let second = this.elapsedTime.getSeconds();


            //hour
            hour = hour % 12;
            hour = (hour * Math.PI / 6) +
                (minute * Math.PI / (6 * 60)) +
                (second * Math.PI / (360 * 60));
            this.drawHand(ctx, hour, radius * 0.5, radius * 0.07);
            //minute
            minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
            this.drawHand(ctx, minute, radius * 0.8, radius * 0.07);
            // second
            // second = (second * Math.PI / 30);
            // this.drawHand(ctx, second, radius * 0.9, radius * 0.02);
        },

        drawHand(ctx, pos, length, width) {
            ctx.beginPath();
            ctx.lineWidth = width;
            ctx.lineCap = "round";
            ctx.moveTo(0, 0);
            ctx.rotate(pos);
            ctx.lineTo(0, -length);
            ctx.stroke();
            ctx.rotate(-pos);
        },

        handleMouseMove(event) {
            const canvas = this.$refs.clockCanvas;
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            canvas.style.cursor = "";
            this.hours.forEach((hour, index) => {
                let angle = (index - 2) * (Math.PI * 2) / 12; // Adjusted angle calculation
                let hx = this.centerX + Math.cos(angle) * (this.radius - 40);
                let hy = this.centerY + Math.sin(angle) * (this.radius - 40);

                const dx = x - hx;
                const dy = y - hy;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < 30) {
                    canvas.style.cursor = "pointer";
                }
            });
        },

        handleCanvasClick(event) {
            const canvas = this.$refs.clockCanvas;
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            this.hours.forEach((hour, index) => {
                let angle = (index - 2) * (Math.PI * 2) / 12; // Adjusted angle calculation
                let hx = this.centerX + Math.cos(angle) * (this.radius - 40);
                let hy = this.centerY + Math.sin(angle) * (this.radius - 40);

                const dx = x - hx;
                const dy = y - hy;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < 30) {
                    this.openDialog(hour);
                }
            });
        }
    }
};
</script>

<style>
.clock {
    position: relative;
    border-radius: 50%;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1), 0 25px 45px rgba(0, 0, 0, 0.1);
    background: #fff;
}
</style>
